<script setup lang="ts">
    import type { MolHeaderDesktopNav } from '#build/components';
    import type { DestopMenuItemProps } from '~/components/atm/DesktopMenuItem.vue';
    import type { MenuItem } from '~/graphql/generated';

    const nuxtApp = useNuxtApp();
    const appStore = useAppStore();
    const { locale, iso2Country, iso2Lang } = useLocales();
    const { showTag, bikeFinderLocaleUrl, handleBikeFinder } = useBikeFinder();

    await appStore.checkWebsite({ locale, iso2Country, iso2Lang });

    const showFlyout = ref(false);
    const mobileLevel = ref<number>(0);
    const mobileLevelTitle = ref<string>('');
    const mobileLevelTitles = ref<string[]>([]);
    const showMobileLevel1 = ref(false);
    const showMobileLevel2 = ref(false);
    const showMobileLevel3 = ref(false);
    const showMobileLevel4 = ref(false);

    const activeMenuLevel1Link = ref('');
    const activeMenuLevel2Link = ref('');
    const activeMenuLevel3Link = ref('');

    const menuLevel2Ref = ref<InstanceType<typeof MolHeaderDesktopNav> | undefined>();
    const menuLevel3Ref = ref<InstanceType<typeof MolHeaderDesktopNav> | undefined>();
    const menuLevel4Ref = ref<InstanceType<typeof MolHeaderDesktopNav> | undefined>();
    const currentOpenMenuLevel1Id = ref('');
    const currentOpenMenuLevel2Id = ref('');
    const currentOpenMenuLevel3Id = ref('');

    const headerElement = ref<HTMLElement | undefined>();
    const featuredDesktop = ref<MenuItem | undefined>();

    const { menuItems } = storeToRefs(appStore);

    const levelTwoItems = computed(() => {
        if (activeMenuLevel1Link.value) {
            const levelOneItem = getItem(activeMenuLevel1Link.value);
            if (levelOneItem) {
                return {
                    items: getItems(levelOneItem.items),
                    cta: levelOneItem.cta,
                    ctavisibility: levelOneItem.ctavisibility,
                };
            }
        }
        return { items: [] };
    });

    const levelThreeItems = computed(() => {
        if (activeMenuLevel2Link.value) {
            const levelTwoItem = getItem(activeMenuLevel2Link.value);
            if (levelTwoItem) {
                return {
                    items: getItems(levelTwoItem.items),
                    cta: levelTwoItem.cta,
                    ctavisibility: levelTwoItem.ctavisibility,
                };
            }
        }
        return { items: [] };
    });

    const levelFourItems = computed(() => {
        if (activeMenuLevel3Link.value) {
            const levelThreeItem = getItem(activeMenuLevel3Link.value);
            if (levelThreeItem) {
                return {
                    items: getItems(levelThreeItem.items),
                    cta: levelThreeItem.cta,
                    ctavisibility: levelThreeItem.ctavisibility,
                };
            }
        }
        return { items: [] };
    });

    // UTIL functions
    const getFocusable = (parent: HTMLElement | undefined, position: 'first' | 'last' = 'first') =>
        (position === 'first' ? parent?.querySelector('a, button') : Array.from(parent?.querySelectorAll('a, button') ?? []).pop()) as
            | HTMLAnchorElement
            | HTMLButtonElement
            | null;

    const getItem = (id: string) => {
        return appStore.menuItemById(id);
    };
    const getItems = (ids: string[] | undefined | null): MenuItem[] => {
        return (ids || []).map(getItem).filter(isDefined);
    };

    const closeMenu = () => {
        closeMobileMenu();
        closeFlyout();
        featuredDesktop.value = undefined;
    };

    useOnNavigation(closeMenu);

    // Mobile functions
    const toggleMobileMenu = () => {
        mobileLevel.value = ~mobileLevel.value & 1;
        showMobileLevel1.value = !showMobileLevel1.value;
    };

    const closeMobileMenu = () => {
        if (!showMobileLevel1.value) return;
        mobileLevel.value = 0;
        mobileLevelTitle.value = '';
        showMobileLevel1.value = false;
        showMobileLevel2.value = false;
        showMobileLevel3.value = false;
        showMobileLevel4.value = false;
        activeMenuLevel2Link.value = '';
        mobileLevelTitles.value = [];
    };

    const backMobileMenu = (level: number) => {
        mobileLevel.value -= 1;

        mobileLevelTitles.value.pop();
        mobileLevelTitle.value = mobileLevelTitles.value[mobileLevel.value - 1] || '';

        if (level === 2) {
            showMobileLevel2.value = false;
        } else if (level === 3) {
            showMobileLevel3.value = false;
        } else if (level === 4) {
            showMobileLevel4.value = false;
        }
    };

    const openMobileLevel = (item: MenuItem) => {
        const m = getItem(item.id);
        if (m && m.items && m.items.length > 0) {
            if (!mobileLevelTitles.value.includes(item.title)) {
                mobileLevel.value += 1;
                mobileLevelTitles.value[mobileLevel.value - 1] = item.title;
            }

            mobileLevelTitle.value = item.title;
            if (mobileLevel.value === 2) {
                showMobileLevel2.value = true;
                showLevel2(item.id);
            } else if (mobileLevel.value === 3) {
                showMobileLevel3.value = true;
                showLevel3(item.id);
            } else if (mobileLevel.value === 4) {
                showMobileLevel4.value = true;
                showLevel4(item.id);
            }
        }
    };

    // Desktop functions
    const openFlyout = (id: string) => {
        const m = getItem(id);
        if (m && m.items && m.items.length > 0) {
            activeMenuLevel1Link.value = id;
            showFlyout.value = true;
            showLevel2(id);
        } else {
            closeFlyout();
        }
    };
    const closeFlyout = () => {
        if (!showFlyout.value) return;
        showFlyout.value = false;
        activeMenuLevel1Link.value = '';
        activeMenuLevel2Link.value = '';
        activeMenuLevel3Link.value = '';
        featuredDesktop.value = undefined;
    };

    const showLevel2 = (id: string) => {
        activeMenuLevel2Link.value = '';
        activeMenuLevel3Link.value = '';
        featuredDesktop.value = undefined;
        const levelOneItem = getItem(id);
        if (levelOneItem) {
            activeMenuLevel1Link.value = id;
            currentOpenMenuLevel1Id.value = textToId(levelOneItem.title);
        }
    };
    const showLevel3 = (id: string) => {
        activeMenuLevel3Link.value = '';
        featuredDesktop.value = undefined;
        const levelTwoItem = getItem(id);
        if (levelThreeItems.value.items.length) featuredDesktop.value = undefined;

        if (levelTwoItem) {
            activeMenuLevel2Link.value = id;
            currentOpenMenuLevel2Id.value = textToId(levelTwoItem.title);
        }
    };

    const showLevel4 = (id: string) => {
        const levelThreeItem = getItem(id);
        if (levelFourItems.value.items.length) featuredDesktop.value = undefined;

        if (levelThreeItem) {
            activeMenuLevel3Link.value = id;
            currentOpenMenuLevel3Id.value = textToId(levelThreeItem.title);
        }
    };
    const hideLevel3 = () => {
        if (showFlyout.value) {
            activeMenuLevel2Link.value = '';
        }
        featuredDesktop.value = undefined;
    };

    const hideLevel4 = () => {
        if (showFlyout.value) {
            activeMenuLevel3Link.value = '';
        }
        featuredDesktop.value = undefined;
    };

    // A11y functions
    type HandleMenuKeypressParamsType = Parameters<DestopMenuItemProps['handleMenuKeypress']>[number] & {
        itemId: string;
        level: number;
    };

    const handleMenuTab = ({ isOpen, event, level }: HandleMenuKeypressParamsType) => {
        if (isOpen) {
            const refs = [menuLevel2Ref, menuLevel3Ref, menuLevel4Ref];
            const menuItemToFocus = getFocusable(refs[level - 1]?.value?.$el);

            menuItemToFocus?.focus();
            event.preventDefault();
        }

        // # focusTrap
        if (level === 1) return;

        const menuButton = event.target as HTMLElement;
        const parentUl = menuButton.closest('ul');
        if (!parentUl) return;

        const firstItem = getFocusable(parentUl);
        const lastItem = getFocusable(parentUl, 'last');
        if ((firstItem === menuButton && event.shiftKey) || (lastItem === menuButton && !event.shiftKey)) {
            event.shiftKey ? lastItem?.focus() : firstItem?.focus();
            event.preventDefault();
        }
    };
    const handleMenuEnterSpace = ({ isOpen, level, itemId }: HandleMenuKeypressParamsType) => {
        if (level === 1) {
            isOpen ? openFlyout(itemId) : closeFlyout();
        } else if (level === 2) {
            isOpen ? showLevel3(itemId) : hideLevel3();
        } else if (level === 3) {
            isOpen ? showLevel4(itemId) : hideLevel4();
        }
    };
    const focusLastOpenMenu = (level: 2 | 3 | 4) => {
        const lastId = level === 2 ? currentOpenMenuLevel1Id.value : level === 3 ? currentOpenMenuLevel2Id.value : currentOpenMenuLevel3Id.value;
        const toFocus = headerElement.value?.querySelector(`#${lastId}`) as HTMLElement | null;
        toFocus?.focus();
    };
    const handleMenuEsc = ({ level }: HandleMenuKeypressParamsType) => {
        if (level === 2) {
            focusLastOpenMenu(level);
            closeFlyout();
        } else if (level === 3) {
            focusLastOpenMenu(level);
            hideLevel3();
        } else if (level === 4) {
            focusLastOpenMenu(level);
            hideLevel4();
        }
    };
    const handleMenuKeypress = (handleMenuKeypressParams: HandleMenuKeypressParamsType) => {
        const key = handleMenuKeypressParams.event.code;
        if (key === 'Tab') return handleMenuTab(handleMenuKeypressParams);
        if (['Enter', 'NumpadEnter', 'Space'].includes(key)) return handleMenuEnterSpace(handleMenuKeypressParams);
        if (key === 'Escape') return handleMenuEsc(handleMenuKeypressParams);
    };
    const handleDetails = (item: MenuItem) => {
        featuredDesktop.value = item;
    };

    onMounted(() => {
        nuxtApp.$scroll.disable(showMobileLevel1);
    });
</script>

<template>
    <!-- mobile top bar nav -->
    <mol-header-mobile-nav-bar
        class="lg:hidden"
        :menu-level-title="mobileLevelTitle"
        :menu-level="mobileLevel"
        @toggle="toggleMobileMenu()"
        @close="closeMobileMenu()"
        @back="backMobileMenu" />

    <teleport
        to="#vue-root-header"
        :disabled="!showMobileLevel1 && !showMobileLevel2 && !showMobileLevel3 && !showMobileLevel4">
        <!-- mobile level 1 -->
        <transition
            enter-from-class="opacity-0"
            enter-active-class="duration-200 ease-in"
            enter-to-class="opacity-100"
            leave-from-class="opacity-100"
            leave-active-class="duration-200 ease-in"
            leave-to-class="opacity-0">
            <atm-header-mobile-nav v-if="showMobileLevel1">
                <mol-header-mobile-nav
                    :menu="{ items: menuItems }"
                    :menu-level="1"
                    @open="openMobileLevel">
                    <atm-language-button theme="hamburger" />
                    <atm-contact-button
                        show-icon
                        theme="hamburger" />
                    <atm-dealer-button show-icon />
                    <atm-newsletter-button show-icon />
                </mol-header-mobile-nav>
            </atm-header-mobile-nav>
        </transition>
        <!-- mobile level 2 -->
        <transition
            enter-from-class="translate-x-full"
            enter-active-class="duration-300 ease-in"
            enter-to-class="translate-x-0"
            leave-from-class="translate-x-0"
            leave-active-class="duration-300 ease-in"
            leave-to-class="translate-x-full">
            <atm-header-mobile-nav v-if="showMobileLevel2">
                <mol-header-mobile-nav
                    :menu="levelTwoItems"
                    :menu-level="2"
                    @open="openMobileLevel" />
            </atm-header-mobile-nav>
        </transition>
        <!-- mobile level 3 -->
        <transition
            enter-from-class="translate-x-full"
            enter-active-class="duration-300 ease-in"
            enter-to-class="translate-x-0"
            leave-from-class="translate-x-0"
            leave-active-class="duration-300 ease-in"
            leave-to-class="translate-x-full">
            <atm-header-mobile-nav v-if="showMobileLevel3">
                <mol-header-mobile-nav
                    :menu="levelThreeItems"
                    :menu-level="3"
                    @open="openMobileLevel" />
            </atm-header-mobile-nav>
        </transition>
        <!-- mobile level 4 -->
        <transition
            enter-from-class="translate-x-full"
            enter-active-class="duration-300 ease-in"
            enter-to-class="translate-x-0"
            leave-from-class="translate-x-0"
            leave-active-class="duration-300 ease-in"
            leave-to-class="translate-x-full">
            <atm-header-mobile-nav v-if="showMobileLevel4">
                <lazy-mol-header-mobile-nav
                    :menu="levelFourItems"
                    :menu-level="4"
                    @open="openMobileLevel" />
            </atm-header-mobile-nav>
        </transition>
    </teleport>

    <!-- desktop nav -->
    <header
        ref="headerElement"
        class="org-header sticky top-0 z-md flex flex-col drop-shadow max-lg:hidden">
        <!-- desktop top nav bar with icons -->
        <div class="hidden bg-woom-white-smoke lg:block">
            <atm-grid full-width>
                <div class="col-span-2 flex h-12 justify-end md:col-span-12">
                    <div class="flex items-center justify-center gap-4 text-sm">
                        <atm-contact-button />
                        <atm-dealer-button />
                        <atm-newsletter-button />
                        <div class="h-[20px] w-[1px] bg-gray-300"></div>
                        <atm-language-button theme="navbar" />
                        <atm-account-button />
                        <atm-cart-button />
                    </div>
                </div>
            </atm-grid>
        </div>
        <div @mouseleave="closeFlyout">
            <div class="relative z-10 hidden bg-white drop-shadow lg:block">
                <atm-grid full-width>
                    <div class="col-span-full mx-auto flex h-16 w-full items-center justify-between gap-6">
                        <nuxt-link
                            :to="$helpers.generateLocalePath('/')"
                            :aria-label="$t('error.homepage')"
                            class="flex shrink-0">
                            <woom-icon-woom-logo class="h-5 fill-woom-red" />
                        </nuxt-link>

                        <nav class="flex-auto shrink-0">
                            <ul
                                class="flex list-none items-center justify-center gap-8"
                                role="menubar">
                                <li v-for="item in menuItems">
                                    <atm-desktop-menu-item
                                        v-if="item"
                                        :class="['text-lg font-bold', { 'text-woom-red': item.items?.length && activeMenuLevel1Link === item.id }]"
                                        :item="item"
                                        :handle-menu-keypress="
                                            ({ isOpen, event }) => handleMenuKeypress({ isOpen, event, itemId: item.id, level: 1 })
                                        "
                                        v-bind="getMenuItemProps(item)"
                                        @mouseover="() => openFlyout(item.id)" />
                                </li>
                            </ul>
                        </nav>

                        <lazy-atm-button
                            :to="bikeFinderLocaleUrl"
                            @click="handleBikeFinder"
                            styling="solid"
                            size="small"
                            kind="link"
                            :aria-hidden="!showTag"
                            :tabindex="!showTag ? '-1' : undefined"
                            :class="['overflow-hidden text-ellipsis', { 'pointer-events-none opacity-0': !showTag }]"
                            skip-local-path>
                            {{ $t('bikeFinder.label') }}
                        </lazy-atm-button>
                    </div>
                </atm-grid>
            </div>

            <transition
                enter-from-class="opacity-0"
                enter-active-class="duration-300 ease-in"
                enter-to-class="opacity-100"
                leave-from-class="opacity-100"
                leave-active-class="duration-300 ease-in"
                leave-to-class="opacity-0">
                <div v-show="showFlyout">
                    <div class="absolute min-h-[60vh] w-full bg-white lg:py-8">
                        <atm-grid>
                            <mol-header-desktop-nav
                                :menu="levelTwoItems"
                                :submenu-id="currentOpenMenuLevel1Id"
                                :handle-menu-keypress="({ isOpen, event, itemId }) => handleMenuKeypress({ isOpen, event, itemId, level: 2 })"
                                :handle-mouseover="showLevel3"
                                :handle-details="handleDetails"
                                ref="menuLevel2Ref"
                                show-border>
                            </mol-header-desktop-nav>
                            <mol-header-desktop-nav
                                :class="{ hidden: levelThreeItems.items.length === 0 }"
                                :menu="levelThreeItems"
                                :submenu-id="currentOpenMenuLevel2Id"
                                :handle-menu-keypress="({ isOpen, event, itemId }) => handleMenuKeypress({ isOpen, event, itemId, level: 3 })"
                                :handle-mouseover="showLevel4"
                                :handle-details="handleDetails"
                                ref="menuLevel3Ref"
                                show-border />
                            <lazy-mol-header-desktop-nav
                                :class="{ hidden: levelFourItems?.items.length === 0 }"
                                :menu="levelFourItems"
                                :submenu-id="currentOpenMenuLevel3Id"
                                :handle-menu-keypress="({ isOpen, event, itemId }) => handleMenuKeypress({ isOpen, event, itemId, level: 4 })"
                                :handle-details="handleDetails"
                                ref="menuLevel4Ref"
                                show-border />
                            <lazy-org-desktop-menu-feature
                                v-if="featuredDesktop"
                                :featured-desktop="featuredDesktop"
                                :class="[levelFourItems?.items?.length ? 'max-xl:hidden xl:col-span-3' : 'col-span-4 xl:col-span-6']" />
                        </atm-grid>
                    </div>
                </div>
            </transition>
        </div>
    </header>
    <!-- desktop nav overlay -->
    <transition
        enter-from-class="opacity-0"
        enter-active-class="duration-300 ease-in"
        enter-to-class="opacity-100"
        leave-from-class="opacity-100"
        leave-active-class="duration-300 ease-in"
        leave-to-class="opacity-0">
        <lazy-atm-overlay
            v-show="showFlyout"
            class="z-sm" />
    </transition>
</template>
